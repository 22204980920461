import PrivatePolicy from "./utils/PrivatePolicy";

class Site {
	public static PrivatePolicy = PrivatePolicy;

	public static discover($parent?: JQuery): void {
		(<any> $('.lightbox, .fancybox, a[rel="lightbox"]', $parent)).fancybox();

		PrivatePolicy.discover($parent);
	}

	public static init(): void {
		(<any> window).Site = Site;

		PrivatePolicy.init();

		$(() => {
			this.discover();
		});
	}
}

(function($) {
	(<any> $.fn).mainboxes=function() {
		var $this=$(this);

		$this.hover(
			function() {
				$(this).find('header').stop(true).animate({'background-position': '0 -100px'}, 100);
				$(this).find('ul').stop(true).slideDown(100);
			},

			function() {
				$(this).find('header').stop(true).animate({'background-position': '0 0'}, 150);
				$(this).find('ul').stop(true).slideUp(150);
			}
		);
	}

	$(document).ready(function() {
		var $socials=$('#sidesocial');

		$socials.hover(
			function() {
				$(this).stop(true,false).animate({right: 200}, 300);
			},

			function() {
				$(this).stop(true,false).animate({right: 0}, 300);
			}
		);

		//---------------------------------------------------------------------------
		var $pb=$('#promotion .prombody');

		$('#promotion').hover(
			function() {
				$(this).addClass('opened').removeClass('closed');
				$pb.stop(true,false).animate({height: $pb.prop('scrollHeight')}, 300);
			},

			function() {
				$(this).removeClass('opened').addClass('closed');
				$pb.stop(true,false).animate({height: 156}, 300);
			}
		);

		//---------------------------------------------------------------------------
		var $menu=$('#mainmenu');
		$menu.find('.children')
			.hover(
				function() {
					var $mmenu=$(this);
					var $submenu=$mmenu.parent();

					$mmenu.siblings().find('> ul').hide();

					$mmenu.find('> ul')
						.css({
							'z-index': 10,
							'left': !$submenu.parent().is($menu) ? $submenu.outerWidth() : 0
						})
						.stop(true, true).slideDown(150);
				},

				function() {
					var $mmenu=$(this);

					$mmenu.find('> ul').stop(true, true).delay(1300).fadeOut(200);
				}
			);

		//---------------------------------------------------------------------------
		$('.blulistwrap ul').addClass('blulist');

		$('body').on('updatelayout', function () {
			$('.floatbox').each(function () {
				var $box=$(this);

				$box.removeClass('floatbox-on');

				var pos=$box.offset();
				$box.data('float-top', pos.top);
				$box.data('float-width', $box.width());
			});

			$('body').scroll();
		});

		$(window).scroll(function () {
			var stop=$(window).scrollTop();

			$('.floatbox').each(function () {
				var $box=$(this);

				if ($box.data('float-top')<stop) {
					$box.addClass('floatbox-on');
					$box.width($box.data('float-width'));
				} else {
					$box.removeClass('floatbox-on');
					$box.width('');
				}
			});
		});

		$('body').trigger('updatelayout');
	});
})(jQuery);

Site.init();
